function ajaxOnSubmit(form) {

    var name = '#div-' + $(form).attr('id') + '-errors';
// post-submit callback
    function showResponse(responseText, statusText, xhr, $form) {
        var d = responseText;
        if (d.success) {
            window.location.href = d.url;
        }
        else {

            var el = $(name);
            el.show();
            el.html(d.message.join('<br />'));
        }
    }

    function showErr(jqXHR, textStatus, errorThrown) {
        $(name).html("Error ocured: " + textStatus);
    }
    var options = {
        error:showErr,
        success:showResponse  // post-submit callback
    };

    $(form).ajaxSubmit(options);
    return false;
}